<template>
  <el-container class="index">
    <el-aside class="left">
      <div class="chat-list">
        <div class="chat-item"
             :class="{'is-active':index==0}"
             v-for="(item,index) in list"
             :key="index">
          {{item.label}}
        </div>
      </div>
    </el-aside>
    <el-main class="main">
      <el-container class="chat-window">
        <div class="messages"
             ref="messageContainer">
          <div v-for="(msg, index) in messages"
               :key="index"
               class="message"
               :class="{ 'message-right': msg.username === currentUser, 'message-left': msg.username !== currentUser }">
            <img :src="msg.avatar"
                 class="avatar" />
            <div class="message-content">
              <div class="username">{{ msg.username }}</div>
              <div class="message-bubble">
                <i class="message-copy el-icon-copy-document"
                   @click="handleCopy(msg.text)"></i>
                <p>{{ msg.text }}</p>
              </div>
              <span class="timestamp">{{ msg.time }}</span>
            </div>
          </div>
          <div v-loading="loading"></div>
        </div>
        <div class="input-area">
          <el-input type="textarea"
                    v-model="newMessage"
                    :rows="5"
                    :disabled="loading"
                    @keyup.enter="sendMessage"
                    placeholder="请输入内容" />
          <i class="el-icon-position button"
             :disabled="loading"
             @click="sendMessage"></i>
        </div>
      </el-container>
    </el-main>
  </el-container>

</template>

<script>
import dayjs from 'dayjs'
import store2 from 'store2'
const format = 'YYYY-MM-DD HH:mm:ss'
export default {
  data () {
    return {
      loading: false,
      list: [{
        label: 'siliconflow'
      }, {
        label: 'GPT-4.0'
      }, {
        label: 'GPT-3.5'
      }, {
        label: '文心一言'
      }, {
        label: '通义千问'
      }],
      messages: store2.get('chatgpt') || [],
      newMessage: '',
      currentUser: 'avue-data',
      currentUserAvatar: 'https://avuejs.com/images/logo.png',
    };
  },
  created () {
    if (this.messages.length == 0) {
      this.messages.push(
        { username: '智能助手AI', text: '你好！有什么可以帮助你的？', time: dayjs().format(format), avatar: '/img/ai.svg' },
      )
    }
    this.scrollToBottom();
  },
  methods: {
    getMessage (msg, callback) {
      let messageList = this.messages
      if (messageList.length > 9) {
        this.$message.warning('每人只能体验3条对话记录')
        this.loading = false;
        return
      }
      messageList.forEach(ele => {
        ele.role = ele.username == this.currentUser ? 'user' : 'assistant';
        ele.content = ele.text
      })
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer sk-yjlvpaelrawypivfslfnnanccfgplqhmnvcdxefuiirgvfmp'
        },
        body: JSON.stringify({
          model: 'deepseek-ai/deepseek-v2-chat',
          messages: messageList
        })
      };

      fetch('https://api.siliconflow.cn/v1/chat/completions', options)
        .then(response => response.json())
        .then(response => {
          callback(response.choices[0].message.content)
        })
        .catch(err => console.error(err));
    },
    handleCopy (data) {
      this.$Clipboard({
        text: data
      }).then(() => {
        this.$message.success('复制成功')
      }).catch(() => {
        this.$message.error('复制失败')
      });
    },
    sendMessage () {
      if (this.newMessage.trim() === '') return;
      this.loading = true
      this.messages.push({
        username: this.currentUser,
        text: this.newMessage,
        time: dayjs().format(format),
        avatar: this.currentUserAvatar,
      });
      this.getMessage(this.newMessage, (msg) => {
        this.loading = false;
        this.messages.push(
          { username: '智能助手AI', text: msg, time: dayjs().format(format), avatar: '/img/ai.svg' },
        )
        store2.set('chatgpt', this.messages)
        this.scrollToBottom();
      })

      this.newMessage = '';
      store2.set('chatgpt', this.messages)
      this.$nextTick(() => {
        this.scrollToBottom();
      })
    },
    scrollToBottom () {
      this.$nextTick(() => {
        const messageContainer = this.$refs.messageContainer;
        messageContainer.scrollTop = messageContainer.scrollHeight;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.chat-list {
  width: 100%;
}
.chat-item {
  margin: 8px 5px;
  padding: 10px 10px;
  text-align: center;
  border: 1px solid #2d2d2d;
  color: rgba(255, 255, 255, 0.82);
  border-radius: 5px;
  cursor: pointer;
  &.is-disabled {
    color: #eee;
  }
  &.is-active {
    border-color: #2681ff;
    color: #2681ff;
  }
}
.chat-window {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #2d2d2d;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  padding-top: 40px;
  border-bottom: 1px solid #2d2d2d;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.message-left {
  justify-content: flex-start;
}

.message-right {
  justify-content: flex-end;
}

.avatar {
  padding: 5px;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
  border: 1px solid #2d2d2d;
  background-color: #fff;
}

.message-content {
  max-width: 70%;
}

.message-bubble {
  position: relative;
  background-color: #36373b;
  color: rgba(255, 255, 255, 0.82);
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
}
.message-copy {
  position: absolute;
  right: 5px;
  top: -15px;
  font-size: 12px;
  cursor: pointer;
}

.message-right .message-bubble {
  background-color: #0084ff;
  color: white;
}

.message-right .avatar {
  order: 2;
  margin-left: 10px;
  margin-right: 0;
}

.message-right .message-content {
  align-items: flex-end;
}

.username {
  font-weight: bold;
  color: #fff;
  margin-bottom: 8px;
}

.timestamp {
  display: block;
  font-size: 0.8em;
  color: gray;
  margin-top: 5px;
}

.input-area {
  display: flex;
  align-items: center;
  padding: 10px;
}

.button {
  padding: 0 20px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
</style>
